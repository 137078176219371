<template>
  <div class="list_container">
    <el-row class="list_header">
      <el-col :span="5">{{ name }}</el-col>
      <el-col :offset="4" :span="15" class="step">
        1.选择科室&nbsp;&nbsp;
        <span class="active">2.选择医生</span>&nbsp;&nbsp;3.选择时段&nbsp;&nbsp;4.确认挂号
      </el-col>
    </el-row>
    <Count></Count>

    <el-row class="list_title"></el-row>
    <div class="list_main">
      <el-row class="dayregister_title">
        <div v-if="name === '预约挂号'" class="block">
          <span class="title-item">选择日期：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="title-item">
          姓名：<span>{{ patName }}</span>

          就诊卡号：<span>{{ patCardNo }}</span>
        </div>
      </el-row>
      <div class="itemBox">
        <div v-for="(item, i) in doctorList" :key="i" class="item" @click="info(item)">
          <div class="profile">
            <img :src="item.YSZP" alt="" />
          </div>
          <div class="list">
            <p>医生姓名：{{ item.YSMC || item.MC }}</p>
            <p v-if="name != '预约挂号'">坐诊时间:{{ item.ZZSJ }}</p>
            <p>余号：{{ item.SYHYS || item.SYHY }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import qs from 'qs'
import { getStaffListToday, getAppointmentStaff } from '@/service/register'
import dayjs from 'dayjs'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      deptName: '',
      deptCode: '',
      name: '',
      patName: '',
      doctorList: [],
      options: [
        {
          value: '选项1',
          label: ''
        },
        {
          value: '选项2',
          label: ''
        },
        {
          value: '选项3',
          label: ''
        },
        {
          value: '选项4',
          label: ''
        },
        {
          value: '选项5',
          label: ''
        },
        {
          value: '选项6',
          label: ''
        },
        {
          value: '选项7',
          label: ''
        }
      ],
      value: '',
      jsonDay: [],
      timeSlot: ''
    }
  },
  watch: {
    value(newVal, old) {
      let obj = this.options.find(i => i.value === newVal)
      this.timeSlot = obj.label
      this.doctorList = []
      this.getRegList()
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.KSBM = this.$route.query.KSBM
    this.patCardNo = this.$route.query.patCardNo
    this.getToday()
    this.getDay()
    this.getRegList()
    this.getTomorrow()
  },
  methods: {
    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    info(item) {
      this.$router.push({
        path: '/info',
        query: {
          name: this.name,
          patName: this.patName,
          YSBM: item.YSBM,
          KSBM: this.KSBM,
          BM: item.BM,
          JJ: item.JJ,
          YYRQ: this.value,
          KSRQ: dayjs(new Date()).format('YYYY-MM-DD'),
          JSRQ: this.value,
          YSMC: item.YSMC,
          cardNo: this.$route.query.cardNo,
          patCardNo: this.$route.query.patCardNo,
          patCardType: this.$route.query.patCardType
        }
      })
    },

    // 获取号源
    async getRegList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.name == '当天挂号') {
        const data = { KSBM: this.KSBM }
        const res = await getStaffListToday(data)
        console.log(res)
        if (res.data.code == 0) {
          loading.close()
          if (Array.isArray(res.data.data.DATASET.ROW)) {
            this.doctorList = res.data.data.DATASET.ROW
          } else {
            let item = []
            item.push(res.data.data.DATASET.ROW)
            this.doctorList = item
          }
        } else {
          loading.close()
          this.doctorList = []
          this.$message({
            message: '暂无医生信息',
            type: 'warning'
          })
        }
      } else {
        const data = { KSBM: this.$route.query.BM, KSRQ: this.value, JSRQ: this.value }
        const res = await getAppointmentStaff(data)
        console.log(res)
        if (res.data.code == 0) {
          loading.close()
          if (Array.isArray(res.data.data.DATASET.ROW)) {
            this.doctorList = res.data.data.DATASET.ROW
          } else {
            let item = []
            item.push(res.data.data.DATASET.ROW)
            this.doctorList = item
          }
        } else {
          loading.close()
          this.doctorList = []
          this.$message({
            message: '暂无医生信息',
            type: 'warning'
          })
        }
      }
    },
    //获取今天日期
    getToday() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + '-' + month + '-' + strDate
      this.timeSlot = currentdate
    },
    // 获取明天日期
    getTomorrow() {
      var date = new Date()
      date.setDate(date.getDate() + 1)
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + '-' + month + '-' + strDate
      this.value = currentdate
    },
    //获取一个星期的日期
    getDay() {
      var arr = []
      var day = []
      var week = []
      const options = this.options
      for (var i = 0; i <= 6; i++) {
        let de = i + 1
        day.push(this.formatTime(de))
        week.push(this.formatWeek(this.getWeek(de)))
      }
      for (var i = 0; i < day.length; i++) {
        var str = day[i]
        var s = str.split('-')
        var json = {}
        for (var j = 0; j < s.length; j++) {
          json.year = s[0]
          json.month = s[1]
          json.day = s[2]
        }
        arr.push(json)
      }
      for (var i = 0; i < week.length; i++) {
        arr[i].week = week[i]
      }
      for (var i = 0; i < arr.length; i++) {
        arr[i].checked = false
      }
      for (var j = 0; j < options.length; j++) {
        options[j].label = day[j]
        options[j].value = day[j]
      }
      this.options = options
      arr[0].checked = true
      this.jsonDay = arr
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },

    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear()
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      return [y, m, d].map(this.padStartConvert).join('-')
    },

    //获取周期
    getWeek(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear() //年
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 //月
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate() //日
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    //格式化周期
    formatWeek(date) {
      var weekArray = new Array('日', '一', '二', '三', '四', '五', '六')
      var week = weekArray[new Date(date).getDay()]
      return week
    }
  }
}
</script>
<style lang="less" scoped>
.welcome {
  margin-top: 10%;
  font-size: 42px;
  color: red;
}

.red {
  color: red;
}

.list_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.list_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 45px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.list_header .step {
  font-size: 35px;
  font-weight: 400;
  padding: 0;
  letter-spacing: 0;
  text-align: right;
  padding-right: 30px;
}

.list_header .step .active {
  color: blue;
}
.list_title .block {
  height: 100px;
  position: absolute;
  top: 200px;
  right: 100px;
}

.list_title span {
  font-size: 46px;
  color: #409eff;
}

.list_main {
  margin-top: 10%;
  overflow: auto;
  background-color: #fff;
  max-height: 60%;
}

.list_main .dayregister_title {
  background-color: #409eff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_main .title-item {
  color: #fff;
  font-size: 42px;
}

.title-item span {
  font-weight: 800;
}

.itemBox {
  padding: 60px;
  margin-bottom: 50px;
}

.list_main .item {
  /* float: left; */
  display: inline-block;
  width: 440px;
  color: white;
  font-size: 30px;
  font-weight: 700;
  background-color: #409eff;
  border-radius: 6px;
  margin: 20px;
  padding: 10px 14px;
}

.list_main .item .profile {
  float: left;
  height: 100%;
  width: 130px;
  margin-top: 40px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.list_main .item .profile img {
  width: 100%;
  height: 180px;
}

.list_main .item .list {
  // padding-top: 42px;
}

.list_main .item .list p {
  font-size: 30px;
  margin-top: 23px;
}

.list_main .item .list .tech {
  height: 56px;
  // margin-top: 16px;
}

.list_tip {
  position: fixed !important;
  bottom: 40px;
  left: 0;
  z-index: 1;
  padding-left: 30px;
  color: red;
  font-size: 20px;
}

.list_tip span {
  color: blue;
}

.list_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 35px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 35px !important;
}
</style>
