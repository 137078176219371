import request from '@/utils/request'

//查询当天科室
export function getRegListToday(data) {
  return request({
    url: 'yyHis/getRegListToday',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function getStaffListToday(data) {
  return request({
    url: 'yyHis/getStaffListToday',
    method: 'POST',
    data: data
  })
}

export function getSchedulingList(data) {
  return request({
    url: 'yyHis/getSchedulingList',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function lockRegSave(data) {
  return request({
    url: 'yyHis/lockRegSave',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function getAppointmentDept(data) {
  return request({
    url: 'yyHis/getAppointmentDept',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function getAppointmentStaff(data) {
  return request({
    url: 'yyHis/getAppointmentStaff',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function getAppointmentRegList(data) {
  return request({
    url: 'yyHis/getAppointmentRegList',
    method: 'POST',
    data: data
  })
} //查询当天科室
export function lockRegToday(data) {
  return request({
    url: 'yyHis/lockRegToday',
    method: 'POST',
    data: data
  })
}
export function lockRegTodaySave(data) {
  return request({
    url: 'yyHis/lockRegTodaySave',
    method: 'POST',
    data: data
  })
}
//预约挂号锁号
export function lockReg(data) {
  return request({
    url: 'yyHis/lockReg',
    method: 'POST',
    data: data
  })
}
